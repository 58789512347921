<template>
    <!-- 渠道管理 - 渠道管理 - 查看招生情况 - 学员列表 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">查看招生情况</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员列表</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <div style="margin-left:10px;margin-bottom:20px;">培训名称:{{projectName}} 渠道名称:{{contactUser}}</div>
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="姓名" class="searchboxItem ">
                            <span class="itemLabel">姓名:</span>
                            <el-input v-model="searchForm.userName" placeholder="请输入姓名" type="text" clearable size="small"/>
                        </div>
                        <div title="联系方式" class="searchboxItem ">
                            <span class="itemLabel">联系方式:</span>
                            <el-input v-model="searchForm.mobile" placeholder="请输入联系方式" type="text" clearable size="small"/>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="姓名"
                                prop="userName"
                            />
                            <el-table-column
                                label="联系方式"
                                prop="mobile"
                            />
                            <el-table-column
                                label="报名方式"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary('CT_JOIN_TYPE', row.joinType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="审核结果"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary('CT_USER_AUDIT_STATE', row.auditState)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="备注"
                                prop="auditReason"
                            />
                            <el-table-column
                                label="操作"
                            >
                                <template v-slot="{row, $index}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="toDetail(row, $index)">详情</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>

                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { resetKeepAlive } from "@/utils/common";
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    export default {
        name:"studentList",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        data(){
            return{
                // 搜索区域表单
                searchForm:{
                    mobile:"",//联系方式
                    userName:"",//姓名
                },
                //培训列表数据
                listData:[],
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                // 培训任务id（路由传参而来）
                projectId:"",
                // 渠道id（路由传参而来）
                channelId:"",
                // 培训名称（路由传参而来）
                projectName:"",
                // 渠道名称（路由传参而来）
                contactUser:""
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.projectId=this.$route.query.projectId;
                this.channelId=this.$route.query.channelId;
                this.projectName=this.$route.query.projectName;
                this.contactUser=this.$route.query.contactUser;
                this.getListData();
            },
            // 获取列表数据
            getListData(current){
                const {searchForm,pagination,projectId,channelId}=this;
                if(current) this.pagination.current=current;
                this.$post("/biz/ct/channel/pageListApply",{
                    pageNum: pagination.current,
                    pageSize: pagination.size,
                    projectId,
                    channelId,
                    ...searchForm
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 查看详情
            toDetail(row, $index){
                const {userId,projectId,projectName, contactUser, channelId}=row;
                this.$router.push({
                    path:"/web/studentDetails",
                    query:{
                        projectId,
                        projectName,
                        contactUser,
                        userId,
                        channelId: channelId,
                        // 当前数据下标，从1开始
                        index: (this.pagination.current - 1) * this.pagination.size + $index + 1,
                        total: this.pagination.total,
                        searchForm: JSON.stringify(this.searchForm),
                        type: "departChannel",
                        origin:"渠道学员列表",
                    }
                });
            },
            // 当前页改变
            currentChange(current){
                this.pagination.current=current;
                this.getListData();
            },
            // 每页条数改变
            sizeChange(size){
                this.pagination.size=size;
                this.getListData();
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 32;
                this.tableHeight = tHeight;
            },
        },
        // watch:{
        //     $route(to,from){
        //         if(from.path==="/web/studentDetails"){
        //             this.getListData();
        //         }
        //         // if(to.path==="/web/studentDetails"){
        //         //     from.meta.state=true;
        //         // }
        //         // if(to.path==="/web/institutionalManagement/channelManagement/checkAdmissions"){
        //         //     from.meta.state=false;
        //         // }
        //     }
        // },
        created(){
            this.initData();
        },
        // beforeRouteLeave: resetKeepAlive
        // beforeRouteLeave:function(to,from,next){
        //     if(to.path==="/web/studentDetails"){
        //         from.meta.state=true;
        //     }
        //     if(to.path!=="/web/studentDetails"){
        //         from.meta.state=false;
        //     }
        //     next();
        // }
    }
</script>
